// Header.js
import React, { useContext, useState } from "react";
import { Link,useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import "../style/header.css";
import logo from '../img/logo.png';
import { AppBar, Toolbar, Button, IconButton, Box, Snackbar, Alert, Container, Menu, MenuItem,Modal, TextField } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import SimpleSearchBar from "./SimpleSearchBar";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import axios from "axios";


function Header({}) {
  // Utilisation du contexte utilisateur pour obtenir l'e-mail de l'utilisateur connecté
 
  const {isAuthenticated, setIsAuthenticated} = useContext(AuthContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // État pour la modal de contact
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState("");

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    handleClose(); // Fermer le menu après la fermeture de la modal
  };
  const handleMessageChange = (e) => setMessage(e.target.value);

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  
  // Fonction pour envoyer un message à Discord (ou à votre API)
  const sendMessageToDiscord = async (messageContent) => {
    // Remplacer par votre URL d'API et ajouter le token si nécessaire
    try {
      const response = await axios.post('https://regisse.fr/api/send-message', {
        content: messageContent ,
      });

      if (response.ok) {
        setSnackbarMessage(`Votre message à bien été envoyé, nous allons revenir vers vous rapidement.`);
        setOpenSnackbar(true);
      } else {
        console.error('Erreur lors de l\'envoi du message');
      }
    } catch (error) {
      console.error('Erreur lors de l\'envoi du message', error);
    }
  };

  const handleSubmitMessage = async () => {
    if (message.trim() !== "") {
      await sendMessageToDiscord(message);
      setMessage(""); // Réinitialiser le message après l'envoi
    }
    handleCloseModal(); // Fermer la modal après l'envoi
    
  };

  const handleLogout = () => {
    
    //mis a jour de la variable qui deconnecte l'utilisateur
    setIsAuthenticated(false);

    // Efface le token et l'email du local storage
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    navigate('/login');
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  

  const NavigationLinks = [
    { title: <ShoppingCartIcon />, path: '/Panier' },
    { title: 'Accueil', path: '/' },
    { title: 'Administration', path: '/Admin' },
    { title: 'Commandes', path: '/Commande' },
    // Ajoutez d'autres liens ici
  ];
  
  const UserMenuItems = isAuthenticated ? [
    { title: `Bienvenue, ${localStorage.firstname}`, onClick: handleMenu },
  ] : [
    { title: 'Connexion', path: '/login' },  
  ];

    
   

   

  return (
    <div>
      <AppBar position="static" elevation={0} sx={{ backgroundColor: '#98C1D9',borderBottom: '1px solid #d3d3d3' }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ minHeight: '30px' }}>
          <Link to="/" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <img src={logo} alt="Logo" style={{ height: '40px' }} /> {/* Ajustez la taille selon vos besoins */}
          </Link>
          <SimpleSearchBar/>
          
          <Box sx={{ flexGrow: 1 }} />

            {/* Box pour "Accueil" et "Administration" */}
            <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
            {isAuthenticated && (
              NavigationLinks.map((link) => (
                <Button 
                  key={link.title} 
                  sx={{ my: 2, color: 'white', display: 'block', px: 2 }} 
                  component={Link} 
                  to={link.path}
                >
                  {link.title}
                </Button>
              )
              ))}
              {UserMenuItems.map((item) => (
              <Box sx={{ display: 'flex', alignItems: 'center', px: 2 }}>
                <Button
                  key={item.title}
                  sx={{ my: 2, color: 'white', display: 'block' }}
                  component={Link}
                  to={item.path || '#'}
                  onClick={item.onClick}
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  {item.title}
                </Button>
                {isAuthenticated && (
                  <>
                    <IconButton
                      color="inherit"
                      onClick={handleLogout}
                      sx={{ borderRadius: '0%' }}
                    >
                      <LogoutIcon />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={open}
                      onClose={handleClose}
                    >
                      {/* Ajoutez d'autres éléments de menu ici si nécessaire */}
                      <MenuItem onClick={handleOpenModal}>Nous contacter</MenuItem>
                    </Menu>
                  </>
                )}
              </Box>
            ))}
            </Box>
            <Modal
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="message"
                  label="Votre message"
                  type="text"
                  multiline
                  maxRows={6}
                  fullWidth
                  value={message}
                  sx={{
                    '& label.Mui-focused': {
                      color: 'rgb(152, 193, 217)',
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: 'rgb(152, 193, 217)',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: 'rgb(152, 193, 217)',
                      },
                      '&:hover fieldset': {
                        borderColor: 'rgb(152, 193, 217)',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: 'rgb(152, 193, 217)',
                      },
                    }
                  }}
                  onChange={handleMessageChange}
                />
                <Button onClick={handleSubmitMessage} style={{ width: '100%', marginTop: '20px', background: '#98C1D9', color: '#FFFFFF', boxShadow: 'none' }}>Envoyer</Button>
              </Box>
            </Modal>

                </Toolbar>
                <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Alert
                onClose={handleCloseSnackbar}
                severity="info"
                sx={{
                  width: '100%',
                  backgroundColor: 'white',
                  color: 'black',
                  '& .MuiAlert-icon': {
                    color: '#493657' // couleur de l'icône
                  },
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)' // Exemple d'ombre
                }}
              >
                {snackbarMessage}
              </Alert>
            </Snackbar>
        </Container>
      </AppBar>
  </div>
  );
}

export default Header;
