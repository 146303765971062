import { Link } from "react-router-dom"
import "../style/registerSucces.css"


function RegisterSucces(){
    return(
        <div className="registerSucces">
            <p className="register_txt">Enregistrement Réalisée avec succès</p>
           <Link to="/" className="link_home">
           retour a la page d'accueil</Link>

        </div>
    )
}
export default RegisterSucces