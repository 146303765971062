import ShoppingCart from "../components/ShoppingCart"



function Cart(){
    return(
        <div>
        
        <ShoppingCart />
        </div>
    )
}

export default Cart