import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, Snackbar, Alert, Grid, IconButton, Card, Box, TextField, CardContent, CardMedia, Typography, Tooltip, MenuItem  } from '@mui/material';
import Command from './command';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const CartPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [quantitiesByProductId, setQuantitiesByProductId] = useState({});
  const navigate = useNavigate();


  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleIncrement = (productId) => {
    const currentDays = daysByProductId[productId] || 1;
    const newRentalDays = currentDays + 1;
  
    setDaysByProductId((prevDays) => ({
      ...prevDays,
      [productId]: newRentalDays
    }));
  
    const currentQuantity = quantitiesByProductId[productId] || 1;
    updateCartItem(productId, currentQuantity, newRentalDays);
  };

  const handleDecrement = (productId) => {
    const currentDays = daysByProductId[productId] || 1;
    const newRentalDays = currentDays - 1;
  
    setDaysByProductId((prevDays) => ({
      ...prevDays,
      [productId]: newRentalDays
    }));
  
    const currentQuantity = quantitiesByProductId[productId] || 1;
    updateCartItem(productId, currentQuantity, newRentalDays);
  };

  const [days, setDays] = useState(1);
  const [daysByProductId, setDaysByProductId] = useState({});

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await axios.get('https://regisse.fr/api/cart');
        const initialDays = response.data.reduce((acc, item) => {
          acc[item.id] = item.rental_days || 1; // Initialisez chaque produit avec 1 jour
          return acc;
        }, {});
        const initialQuantities = response.data.reduce((acc, item) => {
          acc[item.id] = item.quantity || 1; // Utilisez la quantité du panier ou initialisez à 1
          return acc;
        }, {});
        setQuantitiesByProductId(initialQuantities);
        setDaysByProductId(initialDays);
        setCartItems(response.data);
        
      } catch (error) {
        console.error("Erreur lors de la récupération du panier", error);
      }
    };

    fetchCartItems();
  }, []);

  const clearCart = async () => {
    try {
      await axios.delete('https://regisse.fr/api/cart');
      setSnackbarMessage(`Votre panier vient d'être remis à zéro.`);
      setOpenSnackbar(true);
      setCartItems([]);
    } catch (error) {
      console.error("Erreur lors de la suppression du panier", error);
    }
  };

  const handleQuantityChange = (productId, newQuantity) => {
    setQuantitiesByProductId(prevQuantities => ({
      ...prevQuantities,
      [productId]: newQuantity
    }));
  
    // Mettre à jour la quantité dans le panier côté serveur si nécessaire
    const currentRentalDays = daysByProductId[productId] || 1;
    updateCartItem(productId, newQuantity, currentRentalDays);
    updateProductQuantity(productId, newQuantity);
  };
  

  const clearProductCart = async (id, productName) => {
    try {
      await axios.delete(`https://regisse.fr/api/cart/${id}`);
      setSnackbarMessage(`"${productName}" a été supprimé du panier`);
      setOpenSnackbar(true);
      const updatedCart = cartItems.filter(item => item.id !== id);
      setCartItems(updatedCart);
    } catch (error) {
      console.error("Erreur lors de la suppression du produit", error);
    }
  };

  const updateProductQuantity = async (id, newQuantity) => {
    try {
      // Mettre à jour la quantité du produit côté serveur
      await axios.put(`https://regisse.fr/api/cart/${id}`, { quantity: newQuantity });

      // Mettre à jour le panier côté client
      const updatedCart = cartItems.map(item => {
        if (item.id === id) {
          return { ...item, quantity: newQuantity };
        }
        return item;
      });

      setCartItems(updatedCart);
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la quantité du produit", error);
    }
  };

  const analyzePriceOptions = (priceDescriptions) => {
    return priceDescriptions.split('|').map(option => {
      const [price, description] = option.split(':');
      return {
        price: parseFloat(price),
        description: description.trim()
      };
    });
  };
  
  const analyzeInterval = (description) => {
    let interval = { min: null, max: null };
    
    const numbers = description.match(/\d+/g);
    if (description.toLowerCase().includes("plus de") && numbers) {
      interval.min = parseInt(numbers[0], 10);
    } else if (description.toLowerCase().includes("mensuel")) {
      interval.min = 30;  // Supposer que le tarif mensuel commence à 30 jours
    } else if (numbers) {
      interval.min = parseInt(numbers[0], 10);
      if (numbers.length > 1) {
        interval.max = parseInt(numbers[1], 10);
      }
    }
    
    return interval;
  };
  
  const calculateTotalPrice = (days, priceOptions) => {
    if (priceOptions.length === 0) {
      return null;
    }
  
    // Gérer le cas où il n'y a qu'un seul prix
    if (priceOptions.length === 1) {
      const singlePriceOption = priceOptions[0];
      return singlePriceOption.price * days; // ou simplement retourner singlePriceOption.price si le prix ne dépend pas des jours
    }
    let selectedOption = null;
  
    for (const option of priceOptions) {
      const interval = analyzeInterval(option.description);
  
      if (interval.min !== null && days >= interval.min && (interval.max === null || days <= interval.max)) {
        // Si l'option est un forfait mensuel et que le nombre de jours est au moins 30
        if (option.description.toLowerCase().includes("mensuel") && days >= 30) {
          // Calculer le prix en divisant le prix mensuel par 30 et en le multipliant par le nombre de jours
          return (option.price / 30) * days;
        }
        
        // Si l'option correspond à l'intervalle de jours et qu'aucune autre option sélectionnée n'a un max plus élevé
        if (!selectedOption || (interval.max !== null && (!selectedOption.interval.max || interval.max > selectedOption.interval.max))) {
          selectedOption = { option, interval };
        }
      }
    }
  
    // Si une option a été sélectionnée, calculer le prix total
    if (selectedOption) {
      return selectedOption.option.price * days;
    }
    
    // Si aucune option n'a été sélectionnée (pas de correspondance), retourner 0 ou null
    return null;
  };
  
  const updateCartItem = async (cartItemId, newQuantity, newRentalDays) => {
    try {
      const payload = {
        cart_item_id: cartItemId,
        new_quantity: newQuantity,
        new_rental_days: newRentalDays
      };
  
      // Envoyer la requête PUT à votre API
      const response = await axios.put('https://regisse.fr/api/cart', payload);
  
      // Gérer la réponse de l'API (facultatif)
      console.log('Mise à jour réussie du panier:', response.data);
      setSnackbarMessage(`Le panier a été mis à jour avec succès.`);
      setOpenSnackbar(true);
  
    } catch (error) {
      console.error("Erreur lors de la mise à jour du panier", error);
      setSnackbarMessage(`Erreur lors de la mise à jour du panier: ${error.message}`);
      setOpenSnackbar(true);
    }
  };
  
  
  const handleCreateOrder = async () => {
      // Vérifier si l'adresse est valide
      /*if (!validateAddress(deliveryAddress.trim())) {
        // L'adresse n'est pas valide
        setIsValidAddress(false);
        return; // Empêche l'exécution de la fonction si l'adresse est vide
      
      }*/
      const selectedDate = dayjs(value).startOf('day');
      const currentDate = dayjs().startOf('day');
      if (selectedDate.isBefore(currentDate)) {
          // Afficher un message d'erreur
          alert('La date de livraison ne peut pas être antérieure à la date du jour.');
          return; // Arrêter l'exécution de la fonction
      }
    const orderDetails = cartItems.map(item => {
      const days = daysByProductId[item.id] || 1;
      const quantity = quantitiesByProductId[item.id] || 1;
      const priceOptions = analyzePriceOptions(item.price_descriptions);
      const totalPrice = calculateTotalPrice(days, priceOptions) || 0;
  
      return {
        id_product: item.product_id,
        duration: days,
        product_number: quantity,
        price: totalPrice, // Assurez-vous que ce prix est correct selon votre logique de tarification
        rent_date: dayjs(value).format('YYYY-MM-DD'), // Utiliser la valeur de DatePicker
        delivery_place: deliveryAddress, // Vous devrez obtenir cette valeur selon votre logique d'application
        id_supplier: item.id_supplier // Assurez-vous que cette valeur est correctement définie dans vos données de produit
      };
    });
  
    try {
      const response = await axios.post('https://regisse.fr/api/createOrder', {
        order_details: orderDetails
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      const responseData = response.data;
      if (response.status === 200) {
        console.log("Commande créée avec succès :", response.data);
      
        // Afficher un message de succès
        setSnackbarMessage(`La commande a été validée avec succès.`);
        setOpenSnackbar(true);
      
        // Vider le panier localement
        setCartItems([]);
      
        // Retarder le rechargement de la page pour que l'utilisateur puisse lire le message
        setTimeout(() => {
          navigate('/Commande');
        }, 1000); // Attendre 4 secondes avant de recharger
      }
      
      // Gérer la réponse de l'API ici (par exemple, afficher un message ou rediriger l'utilisateur)
    } catch (error) {
      console.error('Erreur lors de la création de la commande :', error);
      // Gérer l'erreur ici (par exemple, afficher un message d'erreur à l'utilisateur)
    }
  };


  const [value, setValue] = useState(dayjs().format('YYYY-MM-DD'));
  const todayFormatted = dayjs().format('DD/MM/YYYY');
  const [deliveryAddress, setDeliveryAddress] = useState('');
  const [isValidAddress, setIsValidAddress] = useState(true);

  const calculateGrandTotal = () => {
    return cartItems.reduce((acc, item) => {
      const priceOptions = analyzePriceOptions(item.price_descriptions);
      const totalPrice = calculateTotalPrice(daysByProductId[item.id] || 1, priceOptions) * (quantitiesByProductId[item.id] || 1);
      return acc + (totalPrice ? totalPrice : 0);
    }, 0);
  };
  
  const handleDeliveryAddressChange = (e) => {
    setDeliveryAddress(e.target.value);
    setIsValidAddress(true);
  };
  const validateAddress = (address) => {
    // Expression régulière pour valider l'adresse (numéro de voie, nom de la rue, code postal et ville)
    /*const addressRegex = /^[0-9]+\s+[a-zA-Z\s]+\s+\d{5}\s+[a-zA-Z\s]+$/;
    return addressRegex.test(address);*/
  };

  const grandTotal = calculateGrandTotal();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8} sx={{ pl: 2 }}> {/* Ajout d'une marge à gauche */}
        <h1>Mon Panier</h1>
        {cartItems.length > 0 ? (
          <>
          <Button onClick={clearCart}>Supprimer le panier</Button>
            {cartItems.map((item) => {
            const priceOptions = analyzePriceOptions(item.price_descriptions);
            const totalPrice = (calculateTotalPrice(daysByProductId[item.id] || 1, priceOptions) * (quantitiesByProductId[item.id] || 1)).toFixed(2);
            const formattedTotalPrice = totalPrice !== null ? totalPrice : 'Non disponible';


            return(
            
            <Card key={item.cart_item_id} sx={{ mb: 2, border: '1px solid #d3d3d3', borderRadius: '20px', boxShadow: 'none', overflow: 'hidden', marginLeft: 4}}>
              <CardContent>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Tooltip title={item.name} placement="top">
                    <Typography variant="h6" bold noWrap>
                      <Box sx={{ fontWeight: 'bold' }}>
                        {item.name}
                      </Box>
                    </Typography>
                  </Tooltip>
                  <IconButton 
                    onClick={() => clearProductCart(item.id, item.name)} 
                    sx={{ color: '#98C1D9' }} // Ajustez la couleur si nécessaire
                  >
                    <DeleteIcon /> {/* Assurez-vous que DeleteIcon est importé */}
                  </IconButton>
                </div>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={3}>
                    <CardMedia
                      component="img"
                      sx={{ height: 'auto', maxWidth: 150, maxHeight: 150, objectFit: 'contain', m: 1, borderRadius: 2 }} // Ajout de la largeur et hauteur max, et marges
                      image={`https://regisse.fr/images/${item.image}`}
                      alt={item.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography variant="subtitle1"><Box sx={{ fontWeight: 'bold' }}>{item.name_supplier} - {item.city_supplier}</Box></Typography>
                    <Typography variant="subtitle1"><Box sx={{ fontStyle: 'italic' }}>Réf {item.ref}</Box></Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Typography component="p" style={{ margin: 0 }}>
                      Nombre de jours ouvré
                    </Typography>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #F5F5F5', borderRadius: '20px', padding: '4px' }}>
                      <IconButton onClick={() => handleDecrement(item.id)}  style={{ color: '#98C1D9', padding: '5px' }} disabled={daysByProductId[item.id] === 1}>
                        <RemoveIcon style={{ fontSize: 'small' }} />
                      </IconButton>
                      <TextField
                        id="outlined-number"
                        type="number"
                        value={daysByProductId[item.id] || 1}
                        variant="standard"
                        InputProps={{
                          sx: {
                            "& input": {
                              textAlign: "center",
                              fontSize: '0.875rem'
                            }
                          },
                          inputProps: { min: 1 },
                          notchedOutline: { border: 'none' },
                          disableUnderline: true,
                        }}
                        inputProps={{
                          style: { textAlign: 'center' },
                        }}
                        style={{ width: '30px', textAlign: 'center', margin: '0 5px' }}
                        onChange={(e) => handleIncrement(item.id)}
                      />
                      <IconButton onClick={() => handleIncrement(item.id)} style={{ color: '#98C1D9', padding: '5px' }}>
                        <AddIcon style={{ fontSize: 'small' }} />
                      </IconButton>
                    </div>
                  </div>
                  <br></br>
                  <div>
                    {/* DatePicker non contrôlé avec une valeur par défaut */}
                    <TextField
                      id="date"
                      label="Date de livraison"
                      type="date"
                      defaultValue={todayFormatted}
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      sx={{ width: '100%', mb: 2 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography variant="subtitle1">
                      <Box sx={{ fontWeight: 'bold' }}>
                      {formattedTotalPrice}€
                      </Box>
                     </Typography>
                     <br></br>
                    <Typography variant="subtitle1">
                     
                      <TextField
                      select
                      label="Quantité"
                      value={quantitiesByProductId[item.id] || 1}
                      onChange={(e) => handleQuantityChange(item.id, Number(e.target.value))}
                      sx={{ width: '80px' }}
                    >
                      {[...Array(100).keys()].map((option) => (
                        <MenuItem key={option + 1} value={option + 1}>
                          {option + 1}
                        </MenuItem>
                      ))}
                    </TextField>
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            );
          })}
          </>
        ) : (
          <div style={{paddingLeft: '16px'}}>
            <p>Votre panier est vide.</p>
          </div>
        )}
      </Grid>
      {cartItems.length > 0 ? (
        <Grid item xs={12} md={4}>
      <Grid container direction="column" justifyContent="flex-start">
      <Card sx={{ mb: 2, border: '1px solid #d3d3d3', borderRadius: '20px', boxShadow: 'none', overflow: 'hidden', marginTop: '8px', marginRight: '8px' }}> {/* Ajustez marginTop selon vos besoins */}
          <CardContent>
          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
            Total: {grandTotal.toFixed(2)}€
          </Typography>

          <TextField
        id="delivery-address"
        label="Adresse de livraison"
        value={deliveryAddress}
        onChange={handleDeliveryAddressChange}
        error={!isValidAddress} // Appliquer une erreur si l'adresse n'est pas valide
        helperText={!isValidAddress && "Veuillez saisir une adresse valide"}
        fullWidth
        margin="normal"
      />
           
          <Button 
            variant="contained" 
            color="primary" 
            fullWidth 
            onClick={handleCreateOrder}
            sx={{ borderRadius: '5px', background: '#98C1D9' }}
          >
            Commander
          </Button>
        </CardContent>
      </Card>
      </Grid>
      </Grid>
      ):(
        <div></div>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="info"
          sx={{
            width: '100%',
            backgroundColor: 'white',
            color: 'black',
            '& .MuiAlert-icon': {
              color: '#493657' // couleur de l'icône
            },
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)' // Exemple d'ombre
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      </Grid>
      
  );
};

export default CartPage;
