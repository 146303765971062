import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardMedia, CardContent, Typography, Tooltip, Button, Grid, TextField, Snackbar, Alert } from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import '../style/product.css'
import ShoppingCart from '../components/ShoppingCart';
import axios
 from 'axios';

function TruncatedText({ text }) {
  const isLongText = text.length > 29;
  const displayText = isLongText ? text.substring(0, 29) + '...' : text;

  return (
    <Tooltip title={isLongText ? text : ''} placement="top">
      <Typography variant="h5" component="h2" 
      style={{ 
        fontSize: "21px", 
        fontWeight: 'bold', 
        textAlign: 'center',
        whiteSpace: 'nowrap',  // Garde le texte sur une seule ligne
        overflow: 'hidden',    // Cache le texte qui dépasse du conteneur
        textOverflow: 'ellipsis' // Ajoute des points de suspension si le texte est coupé
      }} >
        {displayText}
      </Typography>
    </Tooltip>
  );
}

function TruncatedTextCaract({ text, isBold }) {
  const isLongText = text.length > 20;
  const displayText = isLongText ? text.substring(0, 20) + '...' : text;

  if (isBold === 1){ 
    return (
      <Tooltip title={isLongText ? text : ''} placement="top">
        <Typography variant="body2" component="span" style={{ fontWeight: 'bold' }}>
            {displayText}
        </Typography>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={isLongText ? text : ''} placement="top">
          <Typography variant="body2" component="span" >
              {displayText}
          </Typography>
        </Tooltip>
    )
  }
}

function FormattedCaract({ caract }) {
  if (!caract) {
    return null; // ou <></> pour un fragment vide si vous ne voulez rien afficher
  }

  const parts = caract.split('|');
  const leftPart = parts[0] ? parts[0].trim() : '';
  const rightPart = parts[1] ? parts[1].trim() : '';

  return (
    <Grid container>
      <Grid item xs={6}>
        <TruncatedTextCaract text={leftPart} isBold={1}/>
      </Grid>
      <Grid item xs={6} style={{ textAlign: 'right' }}>
        <TruncatedTextCaract text={rightPart} isBold={0}/>
      </Grid>
    </Grid>
  );
}

function Caracteristiques({ caract1, caract2, caract3 }) {
  const hasNoCaract = !caract1 && !caract2 && !caract3;

  return (
    <ul style={{ listStyle: 'none', padding: '0px', color: hasNoCaract ? 'grey' : 'black' }}>
      {hasNoCaract ? (
        <li>Aucune caractéristique disponible</li>
      ) : (
        <>
          {caract1 && <li style={liStyle}><FormattedCaract caract={caract1} /></li>}
          {caract2 && <li style={liStyle}><FormattedCaract caract={caract2} /></li>}
          {caract3 && <li style={liStyle}><FormattedCaract caract={caract3} /></li>}
        </>
      )}
    </ul>
  );
}

const liStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginRight: '10px'
};

function ProductCard({ product }) {
  // État pour gérer la quantité
  const [value, setValue] = useState(1);

  const handleIncrement = () => {
    setValue(prevValue => prevValue + 1);
  };

  const handleDecrement = () => {
    setValue(prevValue => prevValue > 1 ? prevValue - 1 : 1);  // Ne décrémenter que si la valeur est > 1
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    // Permettre une chaîne vide pour la saisie manuelle
    if (newValue === '') {
      setValue('');
    } else {
      setValue(Math.max(1, Number(newValue)));
    }
  };
  
  // Ajoutez une fonction pour gérer le onBlur
  const handleBlur = () => {
    if (value === '' || Number(value) < 1) {
      setValue(1);
    }
  };

  const useStyles = makeStyles({
    noBorderInput: {
      '& .MuiOutlinedInput-root': {
        border: 'none', // Supprime la bordure extérieure
        '& fieldset': {
          border: 'none', // Supprime la bordure intérieure
        },
        '&:hover fieldset': {
          border: 'none', // Supprime la bordure au survol
        },
        '&.Mui-focused fieldset': {
          border: 'none', // Supprime la bordure lorsque le champ est focalisé
        },
      },
    },
  });

  const classes = useStyles();

  const [cart, setCart] = useState([])
  const [message, setMessage] = useState('')
  
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const addToCart = async (productId, rentalDays, quantity, productName) => {
    
    try {
      // Récupérer le panier actuel
      const response = await axios.get('https://regisse.fr/api/cart');
      const cart = response.data;
  
      // Verifie si le produit est déjà présent dans le panier
      const existingProductIndex = cart.findIndex(item => item.product_id === productId);
  
      if (existingProductIndex !== -1) {
        // Si le produit est déjà présent, mettre à jour la quantité
        const updatedCart = [...cart];
        updatedCart[existingProductIndex].quantity += Math.max(1, quantity);
  
  
        // Mettre à jour le panier côté client
        setCart(updatedCart);
      } else {
        // Si le produit n'est pas encore dans le panier, l'ajouter normalement
        const response = await axios.post('https://regisse.fr/api/cart', {
          product_id: productId,
          quantity: existingProductIndex !== -1 ? Math.max(1, quantity) : 1,
          rental_days: Math.max(1, rentalDays)
        });
        setSnackbarMessage(`"${productName}" a été ajouté au panier`);
        setOpenSnackbar(true);
        // Ajouter le nouveau produit au panier côté client
        setCart([...cart, response.data]);
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout au panier", error);
    }
  };
  

  return (
    <>
      <Card className="product-card" style={{ boxShadow: '0px 2px 4px rgba(0,0,0,0.1)' , borderRadius: '20px', position: 'relative', height: '450px',width: '300px' }}>
        <Link to={`/ProductPage/${product.id}`}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
            <CardMedia
              component="img"
              alt={product.name}
              image={`https://regisse.fr/images/${product.image}`}
              onError={(e) => e.target.src = "https://regisse.fr/images/pas-image.png"}
              title={product.name}
              style={{ 
                width: '125px',
                height: '125px',
                objectFit: 'contain',
                objectPosition: 'center' // Centrer l'image dans le cadre
              }}
              sx={{paddingTop: '4px'}}
            />
            </div>
            </Link>
            <CardContent>
            <Link to={`/ProductPage/${product.id}`}>
              <TruncatedText text={product.name} />
              
              <Typography variant="p" component="p" style={{ fontSize: "16px", textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>{product.name_supplier}</span> - {product.city_supplier} - <span style={{ fontStyle: 'italic',fontSize: '10px' }}>{product.ref}</span>
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <Caracteristiques caract1={product.caract1} caract2={product.caract2} caract3={product.caract3} />
              </Typography>
              <Divider variant="middle" style={{ margin: '10px 0' }} />
            </Link>
          <Grid container spacing={0} alignItems="center" style={{ marginTop: '10px' }}>
            <Grid item xs={7}>
              <Typography component="p">Nombre de jours ouvré</Typography>
            </Grid>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #F5F5F5', borderRadius: '20px', padding: '4px' }}>
  <IconButton onClick={handleDecrement} style={{ color: '#98C1D9', padding: '5px' }} disabled={value === 1}>
    <RemoveIcon style={{ fontSize: 'small' }} />
  </IconButton>
  <TextField
    id="outlined-number"
    type="number"
    value={value}
    variant="standard"
    InputProps={{
      sx: {
        "& input": {
          textAlign: "center",
          fontSize: '0.875rem'
        }
      },
      inputProps: { min: 1 },
      notchedOutline: { border: 'none' },
      disableUnderline: true,
    }}
    inputProps={{
      style: { textAlign: 'center' },
    }}
    style={{ width: '30px', textAlign: 'center', margin: '0 5px' }}
    onChange={handleChange}
  />
  <IconButton onClick={handleIncrement} style={{ color: '#98C1D9', padding: '5px' }}>
    <AddIcon style={{ fontSize: 'small' }} />
  </IconButton>
</div>


          </Grid>
          <Grid container spacing={0} alignItems="center" style={{ marginTop: '10px' }}>
            <Grid item xs={6}>
              <Typography component="p">Tarif :</Typography>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {product.price ? (
            <Typography component="p">
                <span style={{ fontWeight: 'bold' }}>{product.price}</span><span style={{fontSize: '10px'}}>€ H/T par jour*</span>
              </Typography>
            ) : (
              <Typography component="p">
                Tarif inconnu
            </Typography>
            )}
            </Grid>
          </Grid>
        </CardContent>
        <div style={{ position: 'absolute', bottom: 0, width: '90%', textAlign: 'center', padding: '16px' }}>
          
          <Button variant="contained" onClick={() => addToCart(product.id, value, product.rentalDays, product.name)} sx={{
        ':hover': {
          transform: 'scale(1.1)', // Mise à l'échelle de 10% sur hover
          
        },
        transition: 'transform 0.3s ease-in-out', // Ajoute une transition fluide à l'effet de mise à l'échelle
      }} style={{ width: '80%', background: '#98C1D9', boxShadow: 'none' }}
         >Ajouter au panier</Button>
         
          {message && <div>{message}</div>}
        </div>
      </Card>
      <Snackbar
      open={openSnackbar}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
      <Alert onClose={handleCloseSnackbar} severity="info" 
        sx={{
          width: '100%',
          backgroundColor: 'white', 
          color: 'black',
          '& .MuiAlert-icon': {
            color: '#493657' // couleur de l'icône
          },
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)' // Exemple d'ombre
        }}
        >
        {snackbarMessage}
      </Alert>
    </Snackbar>
    </>
  );
}

export default ProductCard;
