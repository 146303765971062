import React, { useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { Modal, Box, Typography, TextField, Button,InputAdornment  } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from "../AuthContext";

function SimpleSearchBar() {
  const [searchValue, setSearchValue] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [results, setResults] = useState([]);
  const [key, setKey] = useState(0); 
  const searchInputRef = useRef();
  const [isSearching, setIsSearching] = useState(false);
  const {isAuthenticated} = useContext(AuthContext);

  

  useEffect(() => {
    if (searchValue) {
      const fetchData = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`https://regisse.fr/api/search_product?searchParam=${encodeURIComponent(searchValue)}&limit=5`, {
            headers: {
            }
          });
          setResults(response.data);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            handleCloseModal(); // Ferme la modale
          }
          console.error("Erreur lors de la requête API:", error);
        }
      };

      fetchData();
    }
  }, [searchValue]);

  const navigate = useNavigate();

  const handleSearchSubmit = async (event) => {
    setIsSearching(true);
    try {
      navigate(`/searchproduct?query=${encodeURIComponent(searchValue)}`);
      handleCloseModal();
    } catch (error) {
      // Gérer les erreurs ici
    }
    setIsSearching(false);
  };

  const handleResultClick = (productId) => {
    handleCloseModal(); // Ferme la modal
    navigate(`/ProductPage/${productId}`); // Navigue vers la page du produit
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchSubmit();
    }
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setKey(prev => prev + 1); // Mise à jour de la clé pour forcer le re-render
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%', 
    maxWidth: '600px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    borderRadius: '8px',
  };

  const rowStyle = {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0', 
      outline: `1px solid #98C1D9`, 
    }
  };

  return (
    <>
      {isAuthenticated && (
        <div style={{ position: 'relative', borderRadius: '8px', backgroundColor: '#98C1D9' , paddingLeft: '24px'}}>
        <TextField
          key={key}
          type="search"
          ref={searchInputRef}
          value={searchValue}
          placeholder="Recherche..."
          onClick={() => setOpenModal(true)}
          onChange={handleSearchChange}
          sx={{ 
            backgroundColor: 'white',
            width: '400px', 
            maxWidth: '600px',
            textAlign: 'left',
            minHeight: '30px',
            border: 'none',
            borderRadius: '8px', // Coins intérieurs arrondis
            zIndex: 1, // Placez-le au-dessus de la bordure
            '.MuiInputBase-input': {
              padding: '8px',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#98C1D9 !important', // Couleur de bordure par défaut
                borderWidth: '1px !important',
                borderRadius: '8px', // Largeur de bordure
              },
              '&:hover fieldset': {
                borderColor: '#98C1D9 !important',
                borderWidth: '1px !important', // Couleur de bordure au survol
              },
              '&.Mui-focused fieldset': {
                borderColor: '#98C1D9 !important',
                borderWidth: '1px !important', // Couleur de bordure lors de la mise au point
              },
            },
            '&:hover': {
              backgroundColor: '#f7f7f7',
            },
          }}

        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: '#d3d3d3' }} />
            </InputAdornment>
          ),
        }}
      />
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Recherche
          </Typography>
          <TextField
            id="modal-modal-description"
            value={searchValue}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            autoFocus
            sx={{
              width: '100%', 
              maxWidth: '600px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#98C1D9', // Couleur de bordure par défaut
                },
                '&:hover fieldset': {
                  borderColor: '#98C1D9', // Couleur de bordure au survol (si différente)
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#98C1D9', // Couleur de bordure lors de la mise au point
                },
              },
            }}
           
          />
          <Button key={isSearching ? 'searching' : 'not-searching'} onClick={handleSearchSubmit} variant="contained" style={{  background: '#98C1D9' }}>
            {isSearching ? (
              <CircularProgress 
                size={24} 
                style={{ color: 'white' }}
              />
            ) : (
              "Rechercher"
            )}
          </Button>
          <div>
          <TableContainer component={Paper}>
            <Table>
                <TableBody>
                {results.map((item, index) => (
                    <TableRow key={index} onClick={() => handleResultClick(item.id)} sx={rowStyle}>
                      <TableCell>
                          <img 
                          src={`https://regisse.fr/images/${item.image}`}
                          alt={item.name}
                          onError={(e) => e.currentTarget.src = 'https://via.placeholder.com/150'}
                          style={{ width: '35px', height: 'auto' }}
                          />
                      </TableCell>
                      <TableCell>{item.name}</TableCell>
                      <TableCell>{item.ref}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
          </div>
        </Box>
      </Modal>
      </div>
      )}
    </>
  );
}

export default SimpleSearchBar;
