import React, { useState, useEffect } from 'react';
import { FormGroup, FormControlLabel, Checkbox, TextField, Grid, Card, CardContent } from "@mui/material";

const SupplierFilter = ({ suppliers, onFilterChange, products, onCharSelect, onValueSelect}) => {
  const [checkedSuppliers, setCheckedSuppliers] = useState({});
  const [characteristics, setCharacteristics] = useState({});
  const [selectedChar, setSelectedChar] = useState('');
  const [checkedChars, setCheckedChars] = useState({});
  const [checkedValues, setCheckedValues] = useState({});

  useEffect(() => {
    // Initialisation des fournisseurs
    if (suppliers) {
      const initialSuppliers = suppliers.reduce((acc, supplier) => {
        acc[supplier] = false;
        return acc;
      }, {});
      setCheckedSuppliers(initialSuppliers);
    }

    // Extraction des caractéristiques
    const charMap = {};
    if (products) {
      products.forEach(product => {
        ['caract1', 'caract2', 'caract3'].forEach(charField => {
          if (product[charField]) {
            const [charKey, charValue] = product[charField].split('|').map(s => s.trim());
            charMap[charKey] = charMap[charKey] || new Set();
            charMap[charKey].add(charValue);
          }
        });
      });
    }
    setCharacteristics(charMap);
  }, [suppliers, products]);

  const handleSupplierChange = (event) => {
    const updatedSuppliers = {
      ...checkedSuppliers,
      [event.target.name]: event.target.checked,
    };
    setCheckedSuppliers(updatedSuppliers);
    onFilterChange(updatedSuppliers);
  };

  const handleCharChange = (event, charKey) => {
    const checked = event.target.checked;
    setCheckedChars(prev => ({ ...prev, [charKey]: checked }));

    if (checked) {
      setSelectedChar(charKey);
    } else if (charKey === selectedChar) {
      setSelectedChar('');
    }

    onCharSelect(charKey, checked);
  };

  const handleValueChange = (event, value) => {
    const checked = event.target.checked;
    setCheckedValues(prev => ({ ...prev, [value]: checked }));
    onValueSelect(value, checked);
  };

  return (
    <Grid item xs={12} sm={4} md={3}>
      <Card style={{ borderRadius: '10px', paddingLeft: '8px', marginLeft: '8px', paddingRight: '12px', position: 'relative', width: '250px' }}>
        <CardContent>
        <h2>Filtre</h2>
          <FormGroup>
            <h3>Fournisseurs</h3>
            {Object.keys(checkedSuppliers).map(supplier => (
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={checkedSuppliers[supplier]} 
                    onChange={handleSupplierChange} 
                    style={{ color: '#493657' }}
                    name={supplier} 
                  />
                }
                label={supplier}
                key={supplier}
              />
            ))}
            <br></br>
            <h3>Caractéristiques</h3>
            {characteristics && Object.keys(characteristics).map(charKey => (
              <div key={charKey}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!checkedChars[charKey]}
                      onChange={(e) => handleCharChange(e, charKey)}
                      style={{ color: '#493657' }}
                      name={charKey}
                    />
                  }
                  label={charKey}
                />
                {/* Indentation pour les valeurs */}
                {selectedChar === charKey && characteristics[charKey] && [...characteristics[charKey]].map(value => (
                  <div key={value} style={{ marginLeft: '20px' }}> {/* Indentation ici */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!checkedValues[value]}
                        onChange={(e) => handleValueChange(e, value)}
                        style={{ color: '#493657' }}
                        name={value}
                      />
                    }
                    label={value}
                  />
                </div>
                ))}
              </div>
            ))}
          </FormGroup>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SupplierFilter;





/*import React from 'react';
import { Grid, Card, CardContent, TextField, InputAdornment, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

const SupplierFilter = ({ searchFilter, handleSearchChange, checkedSuppliers, handleCheckboxChange, suppliers,caracts, checkedCaracts, checkedValues,handleFilterChange, caractValues, handleValueFilterChange }) => {
  return (
    <Grid item xs={12} sm={4} md={3}>
      <Card style={{ borderRadius: '10px', paddingLeft: '8px', paddingRight: '12px', position: 'relative', width: '250px' }}>
        <CardContent>
          <h2>Filtre</h2>
          <TextField
            fullWidth
            label="Recherche dans la sélection"
            variant="standard"
            value={searchFilter}
            onChange={handleSearchChange}
            sx={{
                maxWidth: '200px',
                '& label.Mui-focused': {
                  color: 'rgb(152, 193, 217)',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: 'rgb(152, 193, 217)',
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'rgb(152, 193, 217)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgb(152, 193, 217)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'rgb(152, 193, 217)',
                  },
                }
              }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
            <div style={{paddingTop:'8px'}}>
                <h3>Fournisseurs</h3>
                <FormGroup>
                    {suppliers.map(supplier => (
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={checkedSuppliers[supplier]}
                            onChange={() => handleCheckboxChange(supplier)}
                            style={{ color: '#98C1D9' }}
                        />
                        }
                        label={supplier}
                        key={supplier}
                    />
                    ))}
                </FormGroup>
            </div>
            <div style={{ paddingTop: '8px' }}>
            <h3>Caractéristiques</h3>
            <FormGroup>
            {caracts.map(caract => (
            <div key={caract}>
                <FormControlLabel
                control={<Checkbox style={{ color: '#98C1D9' }} onChange={(e) => handleFilterChange(caract, e.target.checked)} />}
                label={caract}
                />
                {checkedCaracts[caract] && caractValues[caract] && caractValues[caract].map(value => (
                <div key={value} style={{ marginLeft: '20px' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                            checked={checkedValues[caract][value]}
                            style={{ color: '#98C1D9' }}
                            onChange={(e) => handleValueFilterChange(caract, value, e.target.checked)}
                        />
                        }
                        label={value}
                        key={value}
                    />
                    </div>
                ))}
            </div>
            ))}
                </FormGroup>
            </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SupplierFilter;*/
