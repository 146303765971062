import React, { useState, useEffect, useMemo  } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Grid,
  Button,
  TextareaAutosize,
  Box,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import '../style/Admin.css'


function Admin({ token }) {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchQuery, setSearchQuery] = useState('');
  const [editableDetails, setEditableDetails] = useState({});
  const [characteristics, setCharacteristics] = useState([]); 


  useEffect(() => {
    // Fetch categories from API
    //console.log(token)
    axios.get('https://regisse.fr/api/categories', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });
  }, []);

  useEffect(() => {
    // Fetch products from API based on selected categories
    const url = new URL('https://regisse.fr/api/getProductsAdmin');
    const params = new URLSearchParams();

    // Pour chaque catégorie sélectionnée, ajoutez un paramètre de recherche séparé
    selectedCategories.forEach((cat) => {
        params.append('categories', cat);
    });

    // Utilisez l'objet URLSearchParams pour construire la chaîne de requête
    url.search = params.toString();
    setProducts([]);
    axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        console.log('Products fetched:', response.data);
  
        setProducts(response.data);
        
        const extractedCharacteristics = response.data.reduce((charList, product) => {
            const productDetails = product.detail.split(';');
            for (let i = 0; i < 3; i++) {
              if (productDetails[i]) {
                const [char] = productDetails[i].split('|');
                if (char && !charList.includes(char)) {
                  charList.push(char);
                }
              }
            }
            return charList;
          }, []);
  
          setCharacteristics(extractedCharacteristics);
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
      });
  }, [selectedCategories]); // Trigger the API request when selectedCategories change

    const handleDetailChange = (event, productId) => {
    setEditableDetails((prevEditableDetails) => ({
        ...prevEditableDetails,
        [productId]: event.target.value,
    }));
    };
  
  
    const startEditingDetail = (productId) => {
        const productDetailWithLineBreaks = products.find((product) => product.id === productId).detail.replace(/;/g, '\n');
        setEditableDetails((prevEditableDetails) => ({
          ...prevEditableDetails,
          [productId]: productDetailWithLineBreaks,
        }));
      };
      
  
      const saveDetailChanges = (productId, productRef) => {
        // Remplacez les sauts de ligne par des ';' avant d'envoyer les modifications au serveur ou de mettre à jour l'état du produit
        const productDetailWithSemicolons = editableDetails[productId].replace(/\n/g, ';').trim();
 // Supprimez les espaces autour de la chaîne
        axios.post('https://regisse.fr/api/update_caracteristique', {
          refProduct: productRef,
          detail: productDetailWithSemicolons,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            console.log('Description mise à jour avec succès');
            setProducts((prevProducts) => {
              return prevProducts.map((product) => {
                if (product.id === productId) {
                  // Mettez à jour la description du produit modifié
                  return {
                    ...product,
                    detail: productDetailWithSemicolons,
                  };
                }
                return product;
              });
            });
            // Mettez à jour l'état ou effectuez d'autres actions nécessaires
          })
          .catch((error) => {
            console.error('Erreur lors de la mise à jour de la description:', error);
          });
    
        // Une fois les modifications enregistrées, supprimez la clé correspondante dans editableDetails
        setEditableDetails((prevEditableDetails) => {
          const updatedEditableDetails = { ...prevEditableDetails };
          delete updatedEditableDetails[productId];
          return updatedEditableDetails;
        });
      };
      
  

  // Handle change in categories selection
  const handleCategoryChange = (event) => {
    setSelectedCategories(event.target.value);
    setSearchQuery('');
  };

  // Handle change in page number
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle change in rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle search query change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Handle image loading error
  const handleImageError = (e) => {
    e.target.onerror = null;
    e.target.src = "https://via.placeholder.com/150";
    e.target.alt = "Pas d'image";
  };

  const handleCharacteristicsChange = (event, productId, productRef) => {
    const { name, value } = event.target;
    let newValue = value;
  
    // Mettre à jour l'état local pour refléter la nouvelle sélection
    setProducts((prevProducts) =>
      prevProducts.map((product) => {
        if (product.id === productId) {
          // Si aucune nouvelle valeur n'est sélectionnée, utilisez la valeur du placeholder
          newValue = newValue || product[name];
          return { ...product, [name]: newValue };
        }
        return product;
      })
    );
  
    // Préparez les valeurs à envoyer, en utilisant la valeur actuelle si aucune nouvelle valeur n'est sélectionnée
    const payload = {
      refProduct: productRef,
      caract1: name === 'caract1' ? newValue : products.find(p => p.id === productId).caract1,
      caract2: name === 'caract2' ? newValue : products.find(p => p.id === productId).caract2,
      caract3: name === 'caract3' ? newValue : products.find(p => p.id === productId).caract3,
    };
  
    // Envoyer une requête POST pour mettre à jour les caractéristiques en base de données
    axios.post(
      'https://regisse.fr/api/update_characteristics',
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        console.log('Caractéristiques mises à jour avec succès');
      })
      .catch((error) => {
        console.error('Erreur lors de la mise à jour des caractéristiques:', error);
      });
  };
  

  const renderCharacteristicsDropdowns = (product) => {
  const placeholders = ['Caract 1', 'Caract 2', 'Caract 3'];

  return (
    <Box display="flex" flexDirection="column">
      {Array.from({ length: 3 }).map((_, index) => {
        const caractKey = `caract${index + 1}`;
        return (
          <FormControl
            key={index}
            className={`dropdown-control ${editableDetails[product.id] ? 'expanded' : ''}`}
          >
            <InputLabel>{placeholders[index]}</InputLabel>
            <Select
              name={caractKey}
              value={product[caractKey]}
              onChange={(event) => handleCharacteristicsChange(event, product.id, product.ref)}
            >
              {/* Assurez-vous que product.detail est défini et non null avant d'appeler .split() */}
              {product.detail && product.detail.split(';').map((char, charIndex) => {
              const charName = char.trim(); // Supprimez les espaces autour du nom
              return (
                <MenuItem key={charIndex} value={charName}>
                  {charName}
                </MenuItem>
              );
            })}
            </Select>
          </FormControl>
        );
      })}
    </Box>
  );
};

  // Filter products based on search query
  const filteredProducts = useMemo(() => {
    if (selectedCategories.length === 0) {
      // Si aucune catégorie n'est sélectionnée, retournez tous les produits sans filtre
      return products;
    }
  
    return products.filter((product) => {
      return (
        product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.ref.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  }, [products, searchQuery, selectedCategories]);

  return (
    <div>
      <Grid container spacing={2} alignItems="center" justify="flex-start">
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <InputLabel>Catégories</InputLabel>
            <Select
              multiple
              value={selectedCategories}
              onChange={handleCategoryChange}
              renderValue={(selected) => selected.map(id => categories.find(cat => cat[0] === id)[1]).join(', ')}
            >
              {categories.map((category) => (
                <MenuItem key={category[0]} value={category[0]}>
                  <Checkbox checked={selectedCategories.includes(category[0])} />
                  <ListItemText primary={category[1]} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            label="Rechercher"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table aria-label="tableau des produits">
          <TableHead>
            <TableRow>
              <TableCell>Fournisseur</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Référence</TableCell>
              <TableCell>Catégorie</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Caractéristiques</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>URL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product) => (
              <TableRow key={product.id}>
                <TableCell><span className="hidden">{product.id}</span><span className="bold-text">{product.supplier_name}</span> - {product.supplier_city}</TableCell>
                <TableCell>{product.name}</TableCell>
                <TableCell>{product.ref}</TableCell>
                <TableCell>{categories.find(cat => cat[0] === product.idCategorie)[1]}</TableCell>
                <TableCell>
                {editableDetails[product.id] !== undefined ? (
                    <TextareaAutosize
                    value={editableDetails[product.id]}
                    onChange={(event) => handleDetailChange(event, product.id)}
                    className="editable-textarea"
                    fullWidth
                    minRows={3}
                    />
                ) : (
                    product.detail.split(';').map((item, index) => {
                    // Divisez chaque item par le tiret, et vérifiez si la partie après le tiret existe avant de l'afficher
                    const parts = item.split('|');
                    return (
                        <p key={index}>
                        <span className="bold-text">{parts[0]}</span>
                        {parts[1] ? ` - ${parts[1]}` : ''}
                        </p>
                    );
                    })
                )}
                </TableCell>
                <TableCell>
                  {renderCharacteristicsDropdowns(product)}
                </TableCell>
                <TableCell>
                  <img
                    src={`https://regisse.fr/images/${product.image}`}
                    alt={product.name}
                    style={{ maxHeight: '150px', maxWidth: '150px' }}
                    onError={handleImageError}
                  />
                </TableCell>
                <TableCell>
                  <a href={product.url} target="_blank" rel="noopener noreferrer">
                    <ArrowForwardIcon />
                  </a><br></br>
                  {editableDetails[product.id] !== undefined ? (
                    <Button onClick={() => saveDetailChanges(product.id, product.ref)}>Enregistrer</Button>
                ) : (
                    <Button onClick={() => startEditingDetail(product.id)}>Modifier</Button>
                )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={filteredProducts.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    </div>
  );
};

export default Admin;