// AuthManager.js
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from './AuthContext';

const AuthManager = () => {
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  }, []);
  

  useEffect(() => {
    const interceptorId = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 403) {
          setIsAuthenticated(false); // Mise à jour de l'état d'authentification
          localStorage.removeItem('token'); // Suppression du token du localStorage
          navigate('/'); // Redirection temporaire vers la page d'accueil
          // Redirection vers /login avec un léger délai pour s'assurer que la page d'accueil est chargée
          setTimeout(() => navigate('/login'), 100);
        }
        return Promise.reject(error);
      }
    );

    // Nettoyage de l'intercepteur lors du démontage du composant
    return () => {
      axios.interceptors.response.eject(interceptorId);
    };
  }, [navigate, setIsAuthenticated]);

  return null; // Ce composant ne rend rien visuellement
};

export default AuthManager;
