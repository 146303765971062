import React, { useState, useContext, useEffect } from "react";
import '../style/login.css'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../components/userContext";
import { AuthContext } from '../AuthContext';

function Login() {
  // State pour stocker l'e-mail, le mot de passe et les messages d'erreur
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
 
  const [errorMessage, setErrorMessage] = useState('');
  
  // Hook de navigation pour rediriger l'utilisateur après la connexion
  const navigate = useNavigate();
  
  // Contexte utilisateur pour gérer l'état de l'utilisateur connecté
  const { user, setUser } = useContext(UserContext);
  const {isAuthenticated, setIsAuthenticated} = useContext(AuthContext);
  
  const { setToken } = useContext(AuthContext);
  
  

  
  // Fonction pour gérer la soumission du formulaire de connexion
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Appel à l'API pour la connexion
      const response = await axios.post("https://regisse.fr/api/login", {
        email: email,
        password: password,         
       
      });

      if (response.status === 200) {
        // Si la connexion réussit, affichage des informations dans la console
        console.log('Connexion réussie !');
        
        

        // Mise à jour du contexte utilisateur, recuperation de l'email
        
        localStorage.setItem('email', email)
        const firstname = (response.data.firstname)
        localStorage.setItem('firstname', firstname)

        // Après une connexion réussie
        const token = response.data.token;
        localStorage.setItem('token', token);
        setToken(token);
        // Après la mise à jour du token dans le localStorage
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        setIsAuthenticated(true);
        // Redirection vers la page d'accueil
        navigate("/");
        
        
      }
    } catch (error) {
      // Gestion des erreurs en cas d'échec de la connexion
      console.error("Erreur lors de la connexion", error);
      setErrorMessage('Identifiant ou mot de passe incorrecte');
    }
  }

  // Fonctions pour mettre à jour les états email et password lors de la saisie de l'utilisateur
  const handleEmail = (e) => {
    setEmail(e.target.value);
  }
  const handlePassword = (e) => {
    setPassword(e.target.value);
  }

  // Rendu du composant de connexion
  return (
    <div className="main">
      <p className="sign" align="center">Connexion</p>
      <form className="form1">
        {/* Champ de saisie pour l'e-mail */}
        <input className="email" type="text" align="center" placeholder="Email" value={email} onChange={handleEmail} />
        
        {/* Champ de saisie pour le mot de passe */}
        <input className="password" type="password" align="center" placeholder="Mot de passe" value={password} onChange={handlePassword} />
        
        {/* Affichage des messages d'erreur */}
        {errorMessage && <p className="txt_error">{errorMessage}</p>}
        
        {/* Bouton de connexion */}
        <button className="submit" onClick={handleLogin}>Connexion</button>
        
        {/* Lien vers la récupération de mot de passe */}
        <p className="forgot" align="center"><a href="#">Mot de passe oublié ?</a></p>
      </form>
    </div>
  )
}

export default Login;
