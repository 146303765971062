import React, { createContext, useState, useEffect } from 'react';

const UserContext = createContext();
const LogContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

const LogProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <LogContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {children}
    </LogContext.Provider>
  );
};

export { UserContext, LogContext, UserProvider, LogProvider };

