/**
 * Filtre les produits en fonction du texte de recherche et des fournisseurs sélectionnés.
 */

export const filterProducts = (products, searchFilter, checkedSuppliers, selectedChars, selectedValues) => {

  return products.filter(product => {

    const matchesSearch = !searchFilter || product.name.toLowerCase().includes(searchFilter.toLowerCase());

    const isCheckedSupplier = Object.keys(checkedSuppliers).every(key => !checkedSuppliers[key]) || checkedSuppliers[product.name_supplier];

    const matchesChars = Object.keys(selectedChars).length === 0 || 
  Object.keys(selectedChars).some(charKey => 
    (product.caract1 && product.caract1.startsWith(charKey)) ||
    (product.caract2 && product.caract2.startsWith(charKey)) ||
    (product.caract3 && product.caract3.startsWith(charKey))
  );

  const matchesValues = Object.keys(selectedValues).length === 0 || 
  Object.keys(selectedValues).some(value => 
    (product.caract1 && product.caract1.endsWith(value)) ||
    (product.caract2 && product.caract2.endsWith(value)) ||
    (product.caract3 && product.caract3.endsWith(value))
  );
    const includeProduct = matchesSearch && isCheckedSupplier && matchesChars && matchesValues;
    return includeProduct;
  });
};




/**
 * Calcule les produits à afficher pour la page courante.
 */
export const getPaginatedProducts = (filteredProducts, currentPage, productsPerPage) => {
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  return filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
};

/**
 * Calcule le nombre total de pages après le filtrage.
 */
export const getTotalPages = (filteredProducts, productsPerPage) => {
  return Math.ceil(filteredProducts.length / productsPerPage);
};




