import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import {UserContext} from "../components/userContext";
import axios from "axios"
import '../style/signUp.css'
import { useNavigate} from "react-router-dom";
import {Button} from "@mui/material"

function SignUp() {
    const [name, setName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [errorMessage, setErrorMessage] = useState('')
    const navigate = useNavigate(); // use navigate permet de renvoyer l'utilisateur sur une autre page.
    const {user, setUser} = useContext(UserContext)

    

    const validateEmail = (email) => {
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return regex.test(email);
    };

    const handleSignUp = async (e) => {
        e.preventDefault();

        if (!validateEmail(email)) {
            setErrorMessage("Adresse e-mail invalide");
            return;
        }
    
        try {
            if (password === confirmPassword) {
                const response = await axios.post("https://regisse.fr/api/account", {
                    email: email,
                    password: password,
                });
    
                if (response.status === 201) {
                    console.log('Inscription réussie !');
                    
                    navigate("/Register_Succes");
                    setUser({email})
                } else {
                    setErrorMessage("Erreur lors de l'inscription. Veuillez réessayer plus tard.");
                    console.error("Erreur lors de l'inscription");
                }
            } else {
                setPasswordsMatch(false);
                setErrorMessage("Erreur : Les mots de passe ne sont pas identiques. Vérifiez votre saisie.")
            }
        } catch (error) {
            console.error("Erreur lors de l'inscription", error);
            if (error.response && error.response.status === 400) {
                setErrorMessage("Cet email existe déjà");
            }
        }
    }
    

    const handleNameChange = (e) => {
        setName(e.target.value);
    }

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    }

    return (
        <div className="page_signUp">
        <div className="contener">
            <p className="signUp" align="center">Inscription</p>
            <form className="form1">
                <input className="name" type="text" value={name} onChange={handleNameChange} align="center" placeholder="Nom" />
                <input className="firstName" type="text" value={firstName} onChange={handleFirstNameChange} align="center" placeholder="Prénom" />
                <input className="email" type="email" value={email} onChange={handleEmailChange} align="center" placeholder="Email" />
                <input className="password" type="password" value={password} onChange={handlePasswordChange} align="center" placeholder="Mot de passe" />
                <input className="confirmPassword" type="password" value={confirmPassword} onChange={handleConfirmPasswordChange} align="center" placeholder="Confirmé votre mot de passe" />
                <Button variant="contained" className="registrer" onClick={handleSignUp} style={{  background: '#98C1D9', boxShadow: 'none' }}>Inscription</Button>
                {errorMessage && <p className="text_error"> {errorMessage} </p>}
                <p className="text" align="center"> Déjà inscrit ? connecter-vous !</p>
                <Link to="/Login">
                <Button variant="contained" className="submit" style={{  background: '#98C1D9', boxShadow: 'none' }}>Connexion</Button>
                </Link>
            </form>
        </div>
        </div>
    )
}

export default SignUp;
