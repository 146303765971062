import React, { useContext, useEffect, useState, } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate} from 'react-router-dom';
import SignUp from './pages/signUp';
import Login from './pages/login';
import RegisterSucces from './pages/registerSucces';
import {UserContext } from './components/userContext';
import Header from './components/header';
import Logout from './pages/logout';
import axios from 'axios';
import { Grid, Button } from '@mui/material';
import Product from './pages/product';
import Admin from './pages/Admin';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SearchProduct from './pages/SearchProduct';
import './police/TT-Chocolates-Trial-Regular.otf';
import './App.css';
import ProductPage from './pages/productPage';
import { AuthProvider, AuthContext  } from './AuthContext'; 
import TokenChecker from './components/TokenChecker';
import Cart from './pages/cart';
import CommandPage from './pages/CommandPage';
import AuthManager from './AuthManager'; 





function Home() {
  const { user, setUser } = useContext(UserContext);
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  const [categories, setCategories] = useState([]);
  const { token } = useContext(AuthContext);

  const theme = createTheme({
    typography: {
      fontFamily: 'MyFont, Arial, sans-serif', // Remplacez par le nom de votre police
    },
  });

  
 // Fonction pour récupérer les catégories depuis l'API
  const fetchData = async () => {
    try {
      // Effectue une requête GET vers l'API pour récupérer les catégories
      const response = await axios.get('https://regisse.fr/api/categories', {
        headers: {
        }
      });
      
      // Met à jour l'état local 'categories' avec les données récupérées

      setCategories(response.data)
    
      
    } catch (error) {
      // En cas d'erreur lors de la requête, affiche l'erreur dans la console
      console.error("Erreur lors de la requête :", error);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

// Utilise useEffect pour déclencher la récupération des catégories après la connexion ou la modification du token
  useEffect(() => {
    // Récupérez les catégories après la connexion
    if (isAuthenticated) {
      fetchData();
    }
  }, [token, isAuthenticated]);// appel de token et de isauthenticated dans la dependence de useEffect afin d'avoir tout les renseignement pour afficher les catégories.


return (
  <ThemeProvider theme={theme}>
    {isAuthenticated && (
    <div style={{maxHeight: '100%'}}>
      <h1> Categories</h1>
      <div>
      <Grid container spacing={3} style={{ justifyContent: 'center' }}>
        {categories ? (
          categories.map((category, index) => (
            <Grid item xs={3} xm={2} key={index} style={{ display: 'flex', justifyContent: 'center' }}>
              <Link 
                to={`/Product/${encodeURIComponent(category)}`} 
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                <Button 
                  variant="outlined" 
                  style={{ 
                    borderRadius: '6px', 
                    border: '1px solid #d3d3d3', 
                    width: '200px',  // Largeur fixe
                    height: '50px',  // Hauteur fixe
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' 
                  }} 
                  title={category[1]}
                >
                  {truncateText(category[1], 30)}
                </Button>
              </Link>
            </Grid>
          ))
        ) : (
          <p>Loading categories...</p>
        )}
      </Grid>
      </div>
    </div>
    )}
    </ThemeProvider>
  );
}


function App() {
    // Récupération des valeurs du contexte utilisateur et du contexte d'authentification 
    const [token, setToken] = useState(localStorage.getItem('token'));
    // Mettez à jour isAuthenticated en fonction de la présence du token
    const [isAuthenticated, setIsAuthenticated] = useState(!!token);
    const theme = createTheme({
      typography: {
        fontFamily: 'MyFont, Arial, sans-serif', // Remplacez par le nom de votre police
      },
    });
    if(localStorage.getItem(token)){
      setIsAuthenticated(true);
    }
    useEffect(() => {
      const axiosRequestInterceptor = axios.interceptors.request.use((config) => {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      }, (error) => {
        return Promise.reject(error);
      });
    
      return () => {
        // Assurez-vous de retirer l'intercepteur lorsque le composant est démonté
        axios.interceptors.request.eject(axiosRequestInterceptor);
      };
    }, []);
    
    
    useEffect(() => {
      const currentToken = localStorage.getItem('token');
      if (currentToken) {
        setIsAuthenticated(true);
        setToken(currentToken);
        // Configurer Axios avec le token ici
        axios.defaults.headers.common['Authorization'] = `Bearer ${currentToken}`;
      } else {
        setIsAuthenticated(false);
      }
    }, []);

    return (
      <div style={{ backgroundColor: '#f9f9f9' }}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
              <Router>
                <Header /> 
                <AuthManager />
                <TokenChecker />
                <Routes> 
                  <Route path="/" element={<Home/>} />
                  <Route path="/Admin" element={<Admin token={localStorage.getItem('token')}/>} />
                  <Route path="/SignUp" element={<SignUp />} />
                  <Route path="/Login" element={<Login />} />
                  <Route path="/Register_Succes" element={<RegisterSucces />} />
                  <Route path="/Logout" element={<Logout />} />
                  <Route path="/searchproduct" element={<SearchProduct />} />
                  <Route path="/Product/:category" element={<Product  token={localStorage.getItem('token')}/>} />
                  <Route path="/ProductPage/:id" element={<ProductPage token={localStorage.getItem('token')}/>} />
                  <Route path="/Panier" element={<Cart token={localStorage.getItem('token')}/>} />
                  <Route path="/Commande" element={<CommandPage token={localStorage.getItem('token')}/>} />
                </Routes>
              </Router>
            </ThemeProvider>
          </AuthProvider>
        </div>
  );
}
export default App