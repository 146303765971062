import { Link } from "react-router-dom"
import "../style/registerSucces.css"


function Logout(){
    return(
        <div className="logout">
            <p className="logout_txt">Vous avez été deconnecter</p>
           <Link to="/login" className="link_home">
           retour a la page d'accueil</Link>

        </div>
    )
}
export default Logout