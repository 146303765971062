import Command from "../components/command"

function CommandPage() {
    return(
        <div>
            <h2>Mes Commandes</h2>
            <Command />
        </div>
    )
}

export default CommandPage