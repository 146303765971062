import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link,useNavigate } from "react-router-dom";
import { Grid, Pagination, Typography } from "@mui/material";
import ProductCards from './ProductCards';
import CircularProgress from '@mui/material/CircularProgress';
import SupplierFilter from '../components/SupplierFilter';
import { filterProducts} from '../components/FilterLogic';
import Button from '@mui/material/Button';


function Product({ token }) {
  const { category } = useParams();
  const splitCategory = category.split(',');
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 30;
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();
  
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setIsLoading(true);
        setProducts([]);
        const response = await axios.get(
          `https://regisse.fr/api/getProductsAdmin?categories=${splitCategory[0]}`,
          { }
        );
        setProducts(response.data);
        setTotalPages(Math.ceil(response.data.length / productsPerPage));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Erreur lors de la récupération des produits :", error);
      }
    };

    fetchProducts();
  }, [category, token]);




  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    window.scrollTo(0, 0);
  };



  
 // Début du filtre
 const [searchFilter, setSearchFilter] = useState('');
 const [suppliersList, setSuppliersList] = useState([]);
 const [selectedSuppliers, setSelectedSuppliers] = useState({});
 const [characteristics, setCharacteristics] = useState({});
 const [selectedChar, setSelectedChar] = useState('');
 const [selectedValue, setSelectedValue] = useState('');
 const [selectedChars, setSelectedChars] = useState({});
 const [selectedValues, setSelectedValues] = useState({});
 const [searchInput, setSearchInput] = useState('');
 const [filteredProducts, setFilteredProducts] = useState([]);


   // Calcul de l'index du premier produit à afficher
   const indexOfLastProduct = currentPage * productsPerPage;
   const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
   const currentFilteredProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);
 
   useEffect(() => {
    setCurrentPage(1);
  }, [filteredProducts]);

   useEffect(() => {
    setTotalPages(Math.ceil(filteredProducts.length / productsPerPage));
  }, [filteredProducts]);

 useEffect(() => {
   if (suppliersList.length > 0) {
     const initialSuppliers = suppliersList.reduce((acc, supplier) => {
       acc[supplier] = false;
       return acc;
     }, {});
     setSelectedSuppliers(initialSuppliers);
   }
 }, [suppliersList]);

 useEffect(() => {
   if (products.length > 0) {
     // Créer une liste de fournisseurs uniques
     const uniqueSuppliers = Array.from(new Set(products.map(p => p.name_supplier)));
     setSuppliersList(uniqueSuppliers);
   }
 }, [products]); // Dépendance aux produits

 const handleSupplierFilterChange = (updatedSuppliers) => {
   setSelectedSuppliers(updatedSuppliers);
   updateFilteredProducts(products, searchFilter, updatedSuppliers, selectedChars, selectedValues);
 };

 useEffect(() => {
   const filtered = filterProducts(products, searchFilter, selectedSuppliers, selectedChars, selectedValues);
   setFilteredProducts(filtered);
 }, [products, searchFilter, selectedSuppliers, selectedChars, selectedValues]);

 const handleCharSelect = (charKey, isChecked) => {
   setSelectedChars(prevChars => {
     const updatedChars = isChecked
       ? { ...prevChars, [charKey]: true }
       : Object.fromEntries(Object.entries(prevChars).filter(([key]) => key !== charKey));
     updateFilteredProducts(products, searchFilter, selectedSuppliers, updatedChars, selectedValues);
     return updatedChars;
   });
 };
 
 const handleValueSelect = (value, isChecked) => {
   setSelectedValues(prevValues => {
     const updatedValues = isChecked
       ? { ...prevValues, [value]: true }
       : Object.fromEntries(Object.entries(prevValues).filter(([key]) => key !== value));
     updateFilteredProducts(products, searchFilter, selectedSuppliers, selectedChars, updatedValues);
     return updatedValues;
   });
 };
 
 
 

 const updateFilteredProducts = (products, searchFilter, checkedSuppliers, selectedChars, selectedValues) => {
  const filtered = filterProducts(products, searchFilter, checkedSuppliers, selectedChars, selectedValues);
  setFilteredProducts(filtered);
 };
 
 
 
 useEffect(() => {
   const charMap = {};
   products.forEach(product => {
     ['caract1', 'caract2', 'caract3'].forEach(charField => {
       if (product[charField]) {
         const [charKey, charValue] = product[charField].split('|').map(s => s.trim());
         charMap[charKey] = charMap[charKey] || new Set();
         charMap[charKey].add(charValue);
       }
     });
   });
   setCharacteristics(charMap);
 }, [products]);

 const goBack = () => {
  navigate(-1); // Cela redirige l'utilisateur vers la page précédente
};

  return (
    <div>
      {/* Affichez les produits ici en utilisant ProductCard */}
      <div>
      <Button
        variant="contained"
        style={{ 
          backgroundColor: '#98C1D9',
          marginLeft: "16px",
          marginTop: "16px",
          '&:hover': {
            backgroundColor: '#76a9bf' // Une teinte légèrement plus foncée pour l'effet de survol
          }
        }}
        onClick={goBack}
      >
        Retour
      </Button>
        <h1>Catégorie: {splitCategory[1]}</h1>
        
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <CircularProgress disableShrink 
            style={{ color: 'rgb(152, 193, 217)' }} 
            thickness={5} />
          </div>
        ) : (
            <>
              <Grid container spacing={0}>
              <SupplierFilter
               
                  suppliers={suppliersList}
                  products={products} 
                  onFilterChange={handleSupplierFilterChange}
                  onCharSelect={handleCharSelect} 
                  onValueSelect={handleValueSelect}
              />
              {currentFilteredProducts.length > 0 ? (
                <Grid item xs={12} sm={3} md={9}>
                  <Grid container spacing={2}>
                    {currentFilteredProducts.map((product) => (
                      <Grid item key={product.id} xs={12} sm={3} md={4}>
                          <ProductCards product={product} />
                      </Grid>
                    ))}
                  </Grid>
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handleChangePage}
                      color="primary"
                      sx={{
                        '& .Mui-selected': {
                          backgroundColor: '#98C1D9',
                          color: 'white',
                        }
                      }}
                    />
                  </div>
                </Grid>
            ) : (
              <Grid item xs={12} sm={3} md={9}>
                <Typography style={{ textAlign: 'center', marginTop: '20px', paddingLeft: '8px' }}>
                  Aucun produit ne correspond à votre recherche !
                </Typography>
                </Grid>
            )}
            </Grid>
            </>
            )}
        
      </div>
      
    </div>
  );
}

export default Product;
