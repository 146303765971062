import axios from "axios";
import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, IconButton, TextField, Alert, Snackbar  } from "@mui/material";
import Slider from "react-slick";
import ProductCards from './ProductCards';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useParams, Link, useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Divider from '@mui/material/Divider';

import '../style/productPage.css';
// Importez les styles de slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ProductPage({token}){
  const{id}= useParams();
  const [products, setProducts] = useState([]);
  const [detail, setDetail] = useState('');
  const [cart, setCart] = useState([])
  const [message, setMessage] = useState('')
  // La chaîne `detail` est divisée en paires en utilisant le point-virgule comme séparateur
  let pairs = detail.split(';');
  // Filtrer les paires vides
  let nonEmptyPairs = pairs.filter(pair => pair.trim() !== '');

  // La méthode `map` est utilisée pour créer un nouveau tableau d'objets à partir des paires
  let objects = nonEmptyPairs.map(pair => {
    // Chaque paire est ensuite divisée en clé et valeur en utilisant le tiret comme séparateur
    let [key, value] = pair.split('|');
    // L'objet est créé avec une structure clé-valeur et retourné
    return { key: key, value: value };
  });
  
  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(
          `https://regisse.fr/api/get_product?id=${id}`,
          { headers: { } }
          );
          setProducts(response.data);
          setDetail(response.data[0].detail);
        } catch (error) {
          console.error("Erreur lors de la récupération des produits :", error);
        }
      };      
      fetchProductDetails();
    }, [id, token]);

     // Carroussel
  const [similarProducts, setSimilarProducts] = useState([]);
  useEffect(() => {
    const fetchSimilarProducts = async () => {
      try {
        const response = await axios.get(`https://regisse.fr/api/similarProducts/${id}`, {
          headers: { },
        });
        setSimilarProducts(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des produits similaires :", error);
      }
    };
    fetchSimilarProducts();
  }, [id, token]);

  // Paramètres pour le carrousel

  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{ display: "block",position: "absolute", 
        left: "-14px", padding: "16px", backgroundColor: "#98C1D9", // Couleur de fond temporaire pour tester
        zIndex: 2 }} // Ajoutez d'autres styles CSS ici
        onClick={onClick}
      >
        <KeyboardDoubleArrowLeftIcon style={{ fontSize: 'large' }} />
      </div>
    );
  };
  
  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{ display: "block",
        position: "absolute",
        right: "0px", // ou ajustez selon les besoins
        padding: "16px",
        backgroundColor: "#98C1D9", // Couleur de fond temporaire pour tester
        zIndex: 2 }} // Ajoutez d'autres styles CSS ici
        onClick={onClick}
      >
        <KeyboardDoubleArrowRightIcon style={{ fontSize: 'large' }} />
      </div>
    );
  };
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
  nextArrow: <NextArrow />,
  };


  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Cela redirige l'utilisateur vers la page précédente
  };

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const addToCart = (productId, quantity, rentalDays, productName) => {
    // Ajouter un produit au panier
    axios.post('https://regisse.fr/api/cart', {
      product_id: productId,
      quantity: Math.max(1, quantity), // Assurez-vous que la quantité est au moins 1
      rental_days: Math.max(1, rentalDays) // Assurez-vous que la durée de location est au moins 1
    })
    .then(response => {
      setCart([...cart, response.data]);
      setSnackbarMessage(`"${productName}" a été ajouté au panier`);
        setOpenSnackbar(true);
    })
    .catch(error => {
      console.error("Erreur lors de l'ajout au panier", error);
    });
  };

  // Une fonction pour afficher chaque caractéristique
  const renderCaracts = (product) => {
    const caracts = [product.caract1, product.caract2, product.caract3].filter(Boolean); // Filtre les caractéristiques non nulles et non vides
  
    if (caracts.length === 0) {
      return <div className="caract">Aucune caractéristique disponible</div>;
    }
  
    return caracts.map((caract, index) => {
      const [key, value] = caract.split('|');
      return (
        <div className="caract" key={index}>
          <span className="caract-key">{key}</span>
          <span className="caract-value">{value}</span>
        </div>
      );
    });
  };

  const renderPrice = (priceData, nombreDeJours) => {
    if (!priceData) {
      return <div className="price">Prix non disponible</div>;
    }
  
    const priceOptions = priceData.split('|').map(option => {
      const [prix, description] = option.split(':');
      return { prix: Number(prix), description };
    });
  
    return (
      <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
        {priceOptions.map((option, index) => {
          const intervalle = analyserIntervalle(option.description);
          const isMensuel = option.description.toLowerCase().includes("mensuel");
          const isApplicable = (isMensuel && nombreDeJours >= 30) ||
                               (!isMensuel && nombreDeJours < 30 && intervalle && 
                                nombreDeJours >= intervalle.min && (intervalle.max === undefined || nombreDeJours <= intervalle.max));
          const style = isApplicable 
            ? { backgroundColor: "#493657", color: "white" } 
            : { backgroundColor: "#FFFFFF", color: "black" }; // Style conditionnel  

            const isTarifSpecial = option.description.toLowerCase().includes("weekend") || 
                               option.description.toLowerCase().includes("mensuel");  
          return (
            <Grid item xs={4} key={index} style={{ padding: '4px' }}>
              <div style={{ borderRadius: '4px', padding: '16px', textAlign: 'center', margin: '4px', ...style }} className="price">
                <p>{option.description || "Aucune information"}</p>
                <p><strong>{option.prix}€</strong></p>
                <p>{isTarifSpecial ? "HT" : "HT/Jour"}</p>
              </div>
            </Grid>
          );
        })}
      </Grid>
    );
  };
  
  
  
  
  

  
const [days, setDays] = useState(1); // Initialiser le nombre de jours


const prixOptions = products.map(product => {
  return product.price_descriptions.split('|').map(option => {
    const [prix, description] = option.split(':');
    return {
      prix: Number(prix),
      description
    };
  });
}).flat(); // Utilisez .flat() pour aplatir le tableau si chaque produit peut avoir plusieurs options



const analyserIntervalle = (description) => {
  if (description.toLowerCase().includes("plus de")) {
    const matches = description.match(/\d+/); // Trouve le nombre dans la description
    if (matches) {
      const min = parseInt(matches[0]);
      return { min }; // Retourne seulement la valeur minimale
    }
  }
  else if (description.toLowerCase().includes("mensuel")) {
    return { min: 30 }; // Indique un plan mensuel
  }
  else {
    const matches = description.match(/\d+/g); // Trouve tous les nombres dans la description
    if (matches && matches.length === 2) {
      return { min: parseInt(matches[0]), max: parseInt(matches[1]) };
    }
  }
  return null;
};

const trouverPrix = (nombreDeJours) => {
  // Cas où il n'y a qu'une seule option de prix
  if (prixOptions.length === 1) {
    const option = prixOptions[0];
    const prixCalcule = option.description.toLowerCase().includes("mensuel") ? option.prix / 30 : option.prix;
    return prixCalcule;
  }

  // Logique existante pour plusieurs options de prix
  let tarifChoisi = null;

  prixOptions.forEach(option => {
    const intervalle = analyserIntervalle(option.description);
    if (intervalle && nombreDeJours >= intervalle.min &&
        (intervalle.max === undefined || nombreDeJours <= intervalle.max)) {
      const prixCalcule = option.description.toLowerCase().includes("mensuel") ? option.prix / 30 : option.prix;
      if (!tarifChoisi || (intervalle.max && intervalle.max > (tarifChoisi.intervalle.max || 0))) {
        tarifChoisi = { prix: prixCalcule, intervalle };
      }
    }
  });

  return tarifChoisi ? tarifChoisi.prix : null;
};


function FormattedDetails({ details }) {
  const detailItems = details
    .split(';')
    .filter(item => item.trim())
    .map(item => {
      if (item.toLowerCase().includes("-") && !item.includes(":")) {
        // Remplacez les "-" par ":"
        return item.replace(/-/g, ":");
      } else {
        return item;
      }
    });

  // Répartir les détails en trois colonnes
  const colLength = Math.ceil(detailItems.length / 3);
  const columns = Array.from({ length: 3 }, (_, i) =>
    detailItems.slice(i * colLength, (i + 1) * colLength)
  );
    

  return (
    <div style={{ backgroundColor: 'white', borderRadius: '15px', padding: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <Grid container alignItems="center">
        {columns.map((col, colIndex) => (
          <React.Fragment key={colIndex}>
            {colIndex !== 0 && <Divider orientation="vertical" flexItem />}
            <Grid item xs>
              {col.map((item, index) => {
                const [key, value] = item.split(':').map(part => part.trim());
                return (
                  <div key={index} style={{ display: 'flex', justifyContent: 'space-between', padding: '8px' }}>
                    <span style={{ fontWeight: 'bold', marginRight: '8px' }}>{key}:</span>
                    <span style={{ textAlign: 'right', flex: 1 }}>{value}</span>
                  </div>
                );
              })}
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </div>
  );
}






const prixTotal = trouverPrix(days); // Calculez le prix total


const handleIncrement = () => {
  setDays(prevDays => Number(prevDays) + 1);
};

const handleDecrement = () => {
  setDays(prevDays => Math.max(1, Number(prevDays) - 1));
};

    return(
      <div style={{ background : "rgb(250, 250, 250)"}}>
        
        
       {products.map((product) => (
        <>
         <div className="product-header">
         <Button
              className="return-button"
              startIcon={<ArrowBackIosNewIcon className="button-icon"/> }
              onClick={goBack}
            >
              Retour
            </Button>
          </div>
          <Grid container spacing={2}>
            {/* Première colonne pour l'image */}
            <Grid item xs={4}>
              <img 
                src={`https://regisse.fr/images/${product.image}`} 
                alt={product.name} 
                className="product-image" 
                style={{ 
                  maxWidth: "350px", 
                  maxHeight: "350px", 
                  width: 'auto', 
                  height: 'auto', 
                  objectFit: 'contain' 
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <p className="product-title">{product.name}</p>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <p className="product-supplier"><strong>{product.name_supplier}</strong> - {product.city_supplier}</p>
                  <p className="product-supplier"><strong>Référence du produit</strong> : {product.ref}</p>
                  <br></br>
                  <div className="second-column">
                    {products.length > 0 && renderCaracts(products[0])}
                    <div style={{color: "#737373"}}>
                      <strong>Différente catégories</strong> : {product.category_names}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="third-column">
                    {products.length > 0 && renderPrice(products[0].price_descriptions, days)}
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '8px' }}>
                      <span style={{ marginRight: '10px' }}>Nombre de jours ouvrés</span>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '3px solid #F5F5F5', borderRadius: '20px'}}>
                        <IconButton onClick={handleDecrement} style={{ color: '#98C1D9' }} disabled={days === 1}>
                          <RemoveIcon />
                        </IconButton>
                        <TextField
                          id="outlined-number"
                          type="number"
                          value={days} 
                          variant="standard"
                          InputProps={{
                            sx: {
                              "& input": {
                                  textAlign: "center"
                              }
                            },
                            inputProps: { min: 1 },
                            notchedOutline: { border: 'none' },
                            disableUnderline: true,
                          }} 
                          inputProps={{
                            style: { textAlign: 'center' },
                          }}                       
                          style={{ width: '40px', textAlign: 'center', margin: '0 10px' }}
                          onChange={(e) => setDays(Math.max(1, Number(e.target.value)))}
                        />
                        <IconButton onClick={handleIncrement} style={{ color: '#98C1D9' }}>
                          <AddIcon />
                        </IconButton>
                      </div>
                    </div><br></br>
                    <div style={{textAlign: 'center'}}>
                      <span>Prix total pour {days} jours ouvrés : <strong>{prixTotal ? `${(prixTotal * days).toFixed(2)}€ HT` : "Non disponible"}</strong></span>
                    </div>
                    <br></br>
                    <div style={{ textAlign: 'center', paddingTop: '8px' }}>
                    <Button variant="contained" onClick={() => addToCart(product.id, 1, days, product.name)} style={{ width: '90%', background: '#98C1D9' }}>
                      Ajouter au panier
                    </Button>
                  </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div style={{ margin: "16px" }}>
            <Typography variant="h3" gutterBottom>
              Caracteristiques
            </Typography>
            
            <div>
              {product.detail && <FormattedDetails details={product.detail} />}
            </div>
            
            <div style={{ textAlign: 'center', paddingTop: '8px', paddingBottom: '16px' }}>
            <Button
            component="a"
            href={products[0].url_supplier}
            variant="contained"
            style={{ width: '600px', background: '#98C1D9' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Redirection vers la page produit chez {product.name_supplier}
          </Button>
            </div>
          </div>
        </>
       ))}
       {/* Carrousel des produits similaires */}
      <div style={{ marginTop: "20px", marginLeft: "16px" }}>
        <Typography variant="h3" gutterBottom>
          Produits similaires
        </Typography>
        <Slider {...settings}>
          {similarProducts.map((product) => (
              <div>
                <ProductCards product={product} />
              </div>
            
          ))}
        </Slider>
        <Snackbar
      open={openSnackbar}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
      <Alert onClose={handleCloseSnackbar} severity="info" 
        sx={{
          width: '100%',
          backgroundColor: 'white', 
          color: 'black',
          '& .MuiAlert-icon': {
            color: '#493657' // couleur de l'icône
          },
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)' // Exemple d'ombre
        }}
        >
        {snackbarMessage}
      </Alert>
    </Snackbar>
      </div>
    </div>
    
  )
}

export default ProductPage