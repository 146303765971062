// TokenChecker.js
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { UserContext } from './userContext';
import { AuthContext } from "../AuthContext";

const TokenChecker = () => {
    const { setUser } = useContext(UserContext);
    const { setIsAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    
    if (token) {
      try {
        const decodedToken = jwtDecode(token);

        if (decodedToken.exp < Date.now() / 1000) {
          setIsAuthenticated(false);
          setUser(null);
          alert("Votre session a expiré. Veuillez vous reconnecter.");
          navigate('/login');
        }
      } catch (error) {
        setIsAuthenticated(false);
        setUser(null);
        alert("Votre session a expiré. Veuillez vous reconnecter.");
        navigate('/login');
      }
    } else {
      setIsAuthenticated(false);
    }
  }, [navigate, setIsAuthenticated, setUser]);

  return null; // Ce composant ne rend rien
};

export default TokenChecker;