import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Collapse, Box, Typography, TablePagination } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function Row({ commande }) {
  const [open, setOpen] = useState(false);
  const [commandDetails, setCommandDetails] = useState([]);

  const fetchCommandDetails = async (idCommande) => {
    try {
      const response = await axios.get(`https://regisse.fr/api/getOrders/${idCommande}`);
      
      const detailsPromises = response.data.map(detail => {
        return axios.get(`https://regisse.fr/api/get_product?id=${detail.id_product}`).then(productResponse => {
          
          const productData = productResponse.data[0]; // Accéder au premier élément du tableau
          return { ...detail, productImage: productData.image, productRef: productData.ref, productName: productData.name, productSupplier: `${productData.name_supplier} - ${productData.city_supplier}` };
      });
      });
      Promise.all(detailsPromises).then(completeDetails => {
        setCommandDetails(completeDetails);
      });
    } catch (error) {
      console.error("Erreur lors de la récupération des détails de la commande", error);
    }
  };
  

  useEffect(() => {
    if (open) {
      fetchCommandDetails(commande.id_commande);
    }
  }, [open, commande.id_commande]);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">{commande.id_commande}</TableCell>
        <TableCell>{dayjs(commande.creation_date).format('DD/MM/YYYY')}</TableCell>
        <TableCell>{commande.total_price}</TableCell>
        <TableCell>{commande.total_products}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Détails de la commande
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell/>
                    <TableCell>Nom</TableCell>
                    <TableCell>Fournisseur</TableCell>
                    <TableCell>Référence</TableCell>
                    <TableCell>Nombre de produits</TableCell>
                    <TableCell>Durée de location</TableCell>
                    <TableCell>Tarif</TableCell>
                    <TableCell>Date de livraison</TableCell>
                    <TableCell>Adresse de livraison</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {commandDetails.map((detail) => (
                    <TableRow key={detail.id}>
                      <TableCell component="th" scope="row">
                        <img src={`https://regisse.fr/images/${detail.productImage}`} 
                        alt="Product" 
                        style={{ 
                          maxWidth: "100px", 
                          maxHeight: "100px", 
                          width: 'auto', 
                          height: 'auto', 
                          objectFit: 'contain' 
                        }}/>
                      </TableCell>
                      <TableCell>{detail.productName}</TableCell>
                      <TableCell>{detail.productSupplier}</TableCell>
                      <TableCell>{detail.productRef}</TableCell>
                      <TableCell>{detail.product_number}</TableCell>
                      <TableCell>{detail.duration}</TableCell>
                      <TableCell>{detail.product_number * detail.price}€</TableCell>
                      <TableCell>{dayjs(detail.rent_date).format('DD/MM/YYYY')}</TableCell>
                      <TableCell>{detail.delivery_place}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const Command = () => {
  const [commandes, setCommandes] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  useEffect(() => {
    const fetchCommandes = async () => {
      try {
        const response = await axios.get('https://regisse.fr/api/getOrders');
        setCommandes(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des commandes", error);
      }
    };

    fetchCommandes();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div style={{ padding: '64px' }}>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Numéro de Commande</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Prix Total</TableCell>
              <TableCell>Nombre de produits différents</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {commandes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((commande) => (
              <Row key={commande.id_commande} commande={commande} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={commandes.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default Command;
