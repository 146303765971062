// Dans un nouveau fichier, par exemple AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();


export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  // Ajout d'un état pour gérer l'authentification
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    setToken(storedToken);
    // Mettre à jour l'état d'authentification basé sur la présence du token
    setIsAuthenticated(!!storedToken);
  }, []);

  return (
    <AuthContext.Provider value={{ token, setToken,  isAuthenticated, setIsAuthenticated  }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
